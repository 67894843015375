import { Link } from 'react-router-dom';

import './home.css'

const Component = () => {

    return(<>

       
  <div>   


<nav className="navbar navbar-inverse navbar-fixed-top" id="navbar">
  <div className="container-fluid">
    <div className="navbar-header">
      <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar" style={{"background-color":"black"}}>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>                        
      </button>
      <a className="navbar-brand" href="#home"><img src="img/codenap.png" className="img-responsive logo" alt="CodeNap" /></a>
    </div>
    <div className="collapse navbar-collapse" id="myNavbar">
     
      <ul className="nav navbar-nav navbar-right" id="nav">
        <li className="different"><a href="#home">Home</a></li>
        <li className="different"><a href="#about">About</a></li>
        <li className="different"><a href="#technologies">Technologies</a></li>
        <li className="different"><a href="#amenities">Amenities</a></li>
        <li className="different"><a href="#contact">Contact</a></li>
       
      </ul>
    </div>
  </div>
</nav>
<div className="container-fluid home-section" id="home">
   <div className="row">
       <div className="col-md-1"></div>
       <div className="col-md-6" style={{"padding-top":"30px;"}}>

        <br /><br />
            <h1>PROFESSIONAL SERVICES IN FULL STACK WEB, APPLICATION 
                AND SOFTWARE DEVELOPMENT
                </h1>  
                <h4>Professional Services in Websites, Mobile Apps and Softwares along with
                    Server Setup, Management and Maintenance.</h4>
                    
                   <br />
               
       </div>
       
            <div className="col-md-2"></div>
              <div className="col-md-1" id="banner_right">
                 </div>
              <div className="col-md-2" id="banner_right">
                
                <div className="row right_list">
                    <ul>
                        <li>Websites</li>
                    </ul>    
                </div>
                
                <div className="row right_list2">
                    <ul>
                        <li>Web Apps</li>
                    </ul>    
                </div><br />
                
                <div className="row right_list3">
                    <ul>
                        <li>Softwares</li>
                    </ul>    
                </div>
                
                <div className="row right_list3">
                    <ul>
                        <li>Mobile Apps</li>
                    </ul>    
                </div>
                <div className="row right_list3">
                    <ul>
                        <li>RPA</li>
                    </ul>    
                </div>
                
                <div className="row right_list3">
                    <ul>
                        <li>AI & ML</li>
                    </ul>    
                </div>
                
                
                
            </div>
       
     
     
   </div> 
</div>
<div className="container about" id="about">
    <div className="row">
        <div className="col-md-5">
            <img src="img/about.png" className="img-responsive imgg" alt="CodeNap" />
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-6">
            <h1>CodeNap - Professional IT Services</h1>
            <p>
            CodeNap offers cost-effective Websites, Softwares & Mobile Applications Services and services. With us, you can dream now and build a business tomorrow.
               </p>
               
                <p>
                We aim to give our consumers exactly what they desire, we strive for perfection. Our dedication and duty are to provide excellent and scalable applications, websites and softwares with top-of-the-line search engine rankings through digital marketing.     
            </p>
           
            
        </div>
    </div>
</div>
<div className="container-fluid service">
    <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
            <div className="row">
                <div className="col-md-4">
                <div className="service-img">
                    <img src="img/service1.png" className="img-responsive" alt="CodeNap" />
                    <h3>Web Development</h3>
                    <p>Go digital with Custom Websites with Payment Gateway.
                    </p>
                </div>
            </div>
            <div className="col-md-4">
                <div className="service-img">
                    <img src="img/service2.png" className="img-responsive" alt="CodeNap" />
                    <h3>Application Development</h3>
                    <p>Get Custom & Scalable Web and Mobile Applications.
                    </p>
                </div>
            </div>
            <div className="col-md-4">
                <div className="service-img">
                    <img src="img/service3.png" className="img-responsive" alt="CodeNap" />
                    <h3>Software Development</h3>
                    <p id="development">
                    Get Proven, Practical & Scalable Custom Softwares.</p>
                </div>
            </div>
            </div>
        </div>
        <div className="col-md-1"></div>
    </div>
</div>



<div className="container-fluid development tech" id="technologies">
    <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
           <h1>Development Technologies</h1> 
           <div className="separator"></div>
           <div className="row">
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>HTML</h4>
                       <h5>Hypertext Markup Language.</h5>
                   </div>
               </div>
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>CSS</h4>
                       <h5>Cascading Style Sheet.</h5>
                   </div>
               </div>
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>BOOTSTRAP</h4>
                       <h5>CSS Framework.</h5>
                </div>
               </div>
               
           </div>
           
           <div className="row">
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>JAVASCRIPT</h4>
                       <h5>Frontend Scripting Language.</h5>
                   </div>
               </div>
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>JQUERY</h4>
                       <h5>JavaScript Framework.</h5>
                   </div>
               </div>
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>JSON</h4>
                       <h5>JavaScript Object Notation.</h5>
                   </div>
               </div>
               
           </div>
           
           
           <div className="row">
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>PHP</h4>
                       <h5>Hypertext Preprocessor.</h5>
                   </div>
               </div>
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>JAVA</h4>
                       <h5>Object-Oriented Programming Language.</h5>
                   </div>
               </div>
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>MYSQL</h4>
                       <h5>Relational DBMS.</h5>
                   </div>
               </div>
               
           </div>
           <div className="row">
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>C</h4>
                       <h5>Procedural Programming Language.</h5>
                   </div>
               </div>
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>C++</h4>
                       <h5>Object-Oriented Programming Language.</h5>
                   </div>
               </div>
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>DSA</h4>
                       <h5>Data Structures & Algorithms.</h5>
                   </div>
               </div>
               
           </div>
           <div className="row">
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>MERN STACK</h4>
                       <h5>MongoDB, Express JS, React JS & Node JS.</h5>
                   </div>
               </div>
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>PYTHON</h4>
                       <h5>General-Purpose Programming Language.</h5>
                   </div>
               </div>
               <div className="col-md-4">
                   <div className="development-service">
                       <h4>REACT NATIVE</h4>
                       <h5>Mobile Application Framework.</h5>
                   </div>
               </div>
               
           </div>
        </div>
        <div className="col-md-1"></div>
    </div>
</div>
<div className="container-fluid amenity" id="amenities">
    <div className="row">
        
        <div className="col-md-1"></div>
        <div className="col-md-10">
            <h1>Development Amenities</h1>
                <div className="separator"></div>
            <div className="row amenities">
                <div className="col-md-6">
                    <img src="img/learn.png" className="img-responsive" alt="CodeNap" />
                </div>
                <div className="col-md-6 amenities_icon">
                    <div className="row amenity_row">
                        
                       
                        
                        <div className="col-md-6">
                            
                           <h5 ><i className="ri-global-line icon"></i> <div style={{"margin-top":"-23px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Free Domain </div></h5>
                           <h5 className="amenityy_icon"><i className="ri-server-line icon"></i> <div style={{"margin-top":"-23px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Free Hosting </div></h5>
                           <h5 className="amenityy_icon"><i className="ri-bug-2-line icon"></i> <div style={{"margin-top":"-23px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Free Maintenance </div></h5>
                           <h5 className="amenityy_icon"><i className="ri-robot-line icon"></i> <div style={{"margin-top":"-23px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Free Support </div></h5>
                           <h5 className="amenityy_icon"><i className="ri-article-line icon"></i> <div style={{"margin-top":"-23px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Free Content Writing </div></h5>
                           
                            
                        </div>
                        <div className="col-md-6 amenity_row1">
                            <h5 ><i className="ri-price-tag-3-line icon"></i> <div style={{"margin-top":"-23px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Cost Effective </div></h5>
                            <h5 className="amenityy_icon"><i className="ri-bar-chart-box-line icon"></i> <div style={{"margin-top":"-23px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; SEO Friendly </div></h5>
                            <h5 className="amenityy_icon"><i className="ri-user-line icon"></i> <div style={{"margin-top":"-23px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Dedicated Manager </div></h5>
                            <h5 className="amenityy_icon"><i className="ri-coupon-5-line icon"></i> <div style={{"margin-top":"-23px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Customizable </div></h5>
                            <h5 className="amenityy_icon"  id="contact"><i className="ri-bank-card-2-line icon"></i> <div style={{"margin-top":"-23px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Pay In EMI's </div></h5>
                            
                           
                                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-1"></div>
    </div>
</div>
<div className="container-fluid contact" >
    <div className="row back" >
        <div className="col-md-1"></div>
        <div className="col-md-10">
            <div className="row">
                <div className="col-md-5" id="contact_div">
                   <center><img src="img/codenap.png" className="img-responsive logo_img" alt="CodeNap" /></center> 
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-5 contact_col">
                    <div className="row">
                            <center><h3 className="contact_heading">Contact Us</h3></center>
                    </div>
                     <br />
                     <br />
                    <div className="row">
                           
                            <div className="col-md-2 col-xs-2">
                                <i className="ri-phone-line icons"></i>
    
                            </div>
                                
                            <div className="col-md-10 col-xs-10">
                                <p className="contact_details">(+91) - 8448332454</p>
                            </div>
                            
                    </div>
                        
                    <div className="row contact_row2">
                           
                            <div className="col-md-2 col-xs-2">
                                <i className="ri-mail-line icons"></i>
                            </div>
                            
                            <div className="col-md-10 col-xs-10">
                                <p className="contact_details">hello@codenap.in</p>
                            </div>
                            
                    </div>
                        
                    <div className="row contact_row2">
                           
                            <div className="col-md-2 col-xs-2">
                                <i className="ri-map-pin-2-line icons"></i>
                            </div>
                            
                            <div className="col-md-10 col-xs-10">
                                <p className="contact_details">&Work Coworking Plot No. 5B, Sector 15A Neelam Chowk Ajronda 
                                Metro Near Crown Plaza, Faridabad, Haryana 121007</p>
                            </div>
                            
                    </div>
                        
                        
                </div>
                    
                <div className="col-md-1"></div>    
                    
            </div>
            
        </div>
        
        <div className="col-md-1"></div>
        
    </div>
    
</div>
<div className="container-fluid footer">
        
        <div className="row footer1">
            
            <div className="col-md-1"></div>
            
            <div className="col-md-3">
                <center>
                    <div className="row">
                        <img src="img/codenap.png" className="img-responsive footer_logo" alt="CodeNap" />
                    </div>
                     </center> 
                    
                    <div className="row footer_row1">
                           
                            <div className="col-md-2 col-xs-2">
                                <i className="ri-phone-line icons"></i>
                            </div>
                            
                            <div className="col-md-10 col-xs-10">
                                <p className="contact_details">(+91) - 8448332454</p>
                            </div>
                            
                        </div>
                        
                        <div className="row contact_row2">
                           
                            <div className="col-md-2 col-xs-2">
                                <i className="ri-mail-line icons"></i>    
                            </div>
                            
                            <div className="col-md-10 col-xs-10">
                                <p className="contact_details">hello@codenap.in</p>
                            </div>
                            
                        </div>
                        
                        <div className="row contact_row2">
                           
                            <div className="col-md-2 col-xs-2">
                                <i className="ri-map-pin-2-line icons"></i>
                            </div>
                            
                            <div className="col-md-10 col-xs-10">
                                <p className="contact_details">&Work Coworking, Plot No. 5B, Sector 15A Neelam Chowk Ajronda 
                                Metro Near Crown Plaza, Faridabad, Haryana 121007</p>
                            </div>
                            
                        </div>
                    
              
            </div>
            
            <div className="col-md-2"></div>
            
            <div className="col-md-6">
                
                <div className="row">

                    
                    
                    <div className="col-md-4 col-xs-6">
                        <p className="footer_heading">Services</p>
                        <p className="footer_content">Website Development</p>
                        <p className="footer_content1">Web App Development</p>
                        <p className="footer_content1">Software Development</p>
                        <p className="footer_content1">Mobile App Development</p>
                        <p className="footer_content1">RPA</p>
                        <p className="footer_content1">AI & ML</p>
                    </div>
                    
                     <div className="col-md-4 col-xs-6">
                        <p className="footer_heading">Resources</p>
                        <p className="footer_content">Frontend Developer</p>
                        <p className="footer_content1">Backend Developer</p>
                        <p className="footer_content1">Full Stack Developer</p>
                        <p className="footer_content1">Software Developer</p>
                        <p className="footer_content1">UI / UX Developer</p>
                        <p className="footer_content1">Project Manager</p>
                    </div>
                    
                    <div className="col-md-4 col-xs-6">
                        <p className="footer_heading">Tech Stack</p>
                        <p className="footer_content">C, C++ & DSA</p>
                        <p className="footer_content1">Web Frontend</p>
                        <p className="footer_content1">PHP & MYSQL</p>
                        <p className="footer_content1">JAVA & MYSQL</p>
                        <p className="footer_content1">MERN STACK</p>
                        <p className="footer_content1">React Native</p>
                    </div>
                    
                </div>
                
            </div>
            
        </div>
        <div className="row" style={{"background-color":"#3B3A3A","padding":"20px"}}>
            
            <div className="col-md-1"></div>
            
            <div className="col-md-6">
            <img src="https://encrobytes.com/img/logo-white.png" style={{"height":"4vh","marginLeft":"-2.2vh"}} alt="Encrobytes"/>
                <h5 style={{"color":"white","margin-left":"-15px"}}>Copyright &#169; 2024 <Link to="https://encrobytes.com" target="_blank">Encrobytes</Link>. All Rights Reserved.</h5>
            </div>
            <div className="col-md-4 footer-right">
                 
            </div>
            
            <div className="col-md-1"></div>
            
        </div>
        
    </div>


</div>


    </>)
}

export default Component;